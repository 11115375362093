<template>
  <div>
    <h2>{{$t('Fulfillment Dashboard')}}</h2>
    <vs-alert class="mt-5" color="warning">
      <span class="flex items-center">
        <feather-icon
          icon="InfoIcon"
          svgClasses="h-4 w-4"
          class="mr-2"
        />
        <span>{{$t('Your account is not subscribed to this feature. Please, contact your account manager to get more information!')}}</span>
      </span>
    </vs-alert>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>